// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barluca-menu-js": () => import("./../../../src/pages/barluca/menu.js" /* webpackChunkName: "component---src-pages-barluca-menu-js" */),
  "component---src-pages-bistecca-menu-js": () => import("./../../../src/pages/bistecca/menu.js" /* webpackChunkName: "component---src-pages-bistecca-menu-js" */),
  "component---src-pages-bistecca-pik-menu-js": () => import("./../../../src/pages/bistecca/pik/menu.js" /* webpackChunkName: "component---src-pages-bistecca-pik-menu-js" */),
  "component---src-pages-bouchon-menu-js": () => import("./../../../src/pages/bouchon/menu.js" /* webpackChunkName: "component---src-pages-bouchon-menu-js" */),
  "component---src-pages-caffemilano-menu-js": () => import("./../../../src/pages/caffemilano/menu.js" /* webpackChunkName: "component---src-pages-caffemilano-menu-js" */),
  "component---src-pages-caffemilano-pik-menu-js": () => import("./../../../src/pages/caffemilano/pik/menu.js" /* webpackChunkName: "component---src-pages-caffemilano-pik-menu-js" */),
  "component---src-pages-corknscrew-countryclub-menu-js": () => import("./../../../src/pages/corknscrew-countryclub/menu.js" /* webpackChunkName: "component---src-pages-corknscrew-countryclub-menu-js" */),
  "component---src-pages-corknscrew-pi-menu-js": () => import("./../../../src/pages/corknscrew/pi/menu.js" /* webpackChunkName: "component---src-pages-corknscrew-pi-menu-js" */),
  "component---src-pages-corknscrew-pp-menu-js": () => import("./../../../src/pages/corknscrew/pp/menu.js" /* webpackChunkName: "component---src-pages-corknscrew-pp-menu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loewy-menu-js": () => import("./../../../src/pages/loewy/menu.js" /* webpackChunkName: "component---src-pages-loewy-menu-js" */),
  "component---src-pages-mailchimp-index-js": () => import("./../../../src/pages/mailchimp/index.js" /* webpackChunkName: "component---src-pages-mailchimp-index-js" */),
  "component---src-pages-modernhaus-menu-js": () => import("./../../../src/pages/modernhaus/menu.js" /* webpackChunkName: "component---src-pages-modernhaus-menu-js" */),
  "component---src-pages-pierre-menu-js": () => import("./../../../src/pages/pierre/menu.js" /* webpackChunkName: "component---src-pages-pierre-menu-js" */),
  "component---src-pages-roma-menu-js": () => import("./../../../src/pages/roma/menu.js" /* webpackChunkName: "component---src-pages-roma-menu-js" */),
  "component---src-pages-roma-pim-menu-js": () => import("./../../../src/pages/roma/pim/menu.js" /* webpackChunkName: "component---src-pages-roma-pim-menu-js" */),
  "component---src-pages-thecocktailclub-menu-js": () => import("./../../../src/pages/thecocktailclub/menu.js" /* webpackChunkName: "component---src-pages-thecocktailclub-menu-js" */),
  "component---src-pages-union-cp-menu-js": () => import("./../../../src/pages/union/cp/menu.js" /* webpackChunkName: "component---src-pages-union-cp-menu-js" */),
  "component---src-pages-union-gi-menu-js": () => import("./../../../src/pages/union/gi/menu.js" /* webpackChunkName: "component---src-pages-union-gi-menu-js" */),
  "component---src-pages-union-mkg-menu-js": () => import("./../../../src/pages/union/mkg/menu.js" /* webpackChunkName: "component---src-pages-union-mkg-menu-js" */),
  "component---src-pages-union-pakuwon-menu-js": () => import("./../../../src/pages/union/pakuwon/menu.js" /* webpackChunkName: "component---src-pages-union-pakuwon-menu-js" */),
  "component---src-pages-union-pik-menu-js": () => import("./../../../src/pages/union/pik/menu.js" /* webpackChunkName: "component---src-pages-union-pik-menu-js" */),
  "component---src-pages-union-pim-menu-js": () => import("./../../../src/pages/union/pim/menu.js" /* webpackChunkName: "component---src-pages-union-pim-menu-js" */),
  "component---src-pages-union-ps-menu-js": () => import("./../../../src/pages/union/ps/menu.js" /* webpackChunkName: "component---src-pages-union-ps-menu-js" */),
  "component---src-pages-union-sc-menu-js": () => import("./../../../src/pages/union/sc/menu.js" /* webpackChunkName: "component---src-pages-union-sc-menu-js" */),
  "component---src-pages-union-sms-menu-js": () => import("./../../../src/pages/union/sms/menu.js" /* webpackChunkName: "component---src-pages-union-sms-menu-js" */)
}

